import { Route, Routes } from "react-router-dom";
import PageTitle from "../components/PageTitle";
import Home from "../pages/Home";

const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        index
        element={
          <>
            <PageTitle
              title={"Toldos Muñoz Martin - Inicio"}
              description={
                "We design your website or ecommerce page. We know perfectly the process that will lead your project to a success story. Custom web development agency for companies. More than 5 years of experience developing web pages that boost sales and conversions. Custom software development for companies Ark Platforms, Inc is a technology consultancy dedicated to the development of custom software for companies. Ark Platforms, Inc We develop custom software, business platforms, web and mobile applications, and dashboards. Our personalized approach ensures that each technological solution fits perfectly to your specific needs, optimizing your processes and enhancing [&hellip;]"
              }
            />
            <Home />
          </>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
