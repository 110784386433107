import Layout from "./layout/Layout";
import AppRoutes from "./routes/route";
import "./App.css";
import "preline/preline";
import { IStaticMethods } from "preline/preline";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
declare global {
  interface Window {
    HSStaticMethods: IStaticMethods;
  }
}

function App() {

  const location = useLocation();

  useEffect(() => {
    window.HSStaticMethods.autoInit();
  }, [location.pathname]);

  return (
    <Layout>
      <AppRoutes />
    </Layout>
  );
}

export default App;
