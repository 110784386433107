import { ArrowRight } from "iconsax-react";
import React from "react";
import { Link } from "react-router-dom";

export default function Home() {
  return (
    <div>
      {/* Hero Section */}
      <section className="relative bg-white py-20">
        <div className="max-w-7xl mx-auto px-4">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
            {/* Text Content */}
            <div className="flex flex-col justify-between">
              <div>
                <h2 className="text-6xl font-medium text-gray-900 mb-6 text-wrap">
                  Elegancia y Protección para tu Hogar
                </h2>
                <p className="text-lg mb-8 text-gray-600">
                  Ofrecemos toldos resistentes a todo tipo de clima. Personaliza
                  tu espacio con nuestros productos y asegura una instalación
                  duradera y con la mejor estética.
                </p>
              </div>

              <Link
                to="#services"
                className="bg-blue-800 text-white px-6 py-6 flex flex-row justify-between items-center rounded-lg font-medium hover:bg-blue-700 transition"
              >
                Descubre nuestros servicios
                <ArrowRight />
              </Link>
            </div>

            {/* Images Grid */}
            <div className="grid grid-cols-2 grid-rows-5 gap-4">
              <div className="relative rounded-lg row-span-2">
                <img
                  src="images/slider2.jpg"
                  alt="Instalación de Toldo 1"
                  className="w-full h-full object-cover rounded-lg"
                />
                <div className="absolute inset-0 flex items-center justify-center text-white text-4xl opacity-30 font-bold">
                  <span>Toldos Muños Martin</span>
                </div>
              </div>

              <div className="relative rounded-lg row-span-3">
                <img
                  src="images/slider1.jpg"
                  alt="Instalación de Toldo 2"
                  className="w-full h-full object-cover rounded-lg"
                />
                <div className="absolute inset-0 flex items-center justify-center text-white text-4xl opacity-30 font-bold">
                  <span>Toldos Muños Martin</span>
                </div>
              </div>

              <div className="relative rounded-lg row-span-3 row-start-3">
                <img
                  src="images/slider3.jpg"
                  alt="Instalación de Toldo 3"
                  className="w-full h-full object-cover rounded-lg"
                />
                <div className="absolute inset-0 flex w-full h-full items-center justify-center text-white text-4xl opacity-30 font-bold">
                  <span>Toldos Muños Martin</span>
                </div>
              </div>

              <div className="relative rounded-lg row-span-2 col-start-2 row-start-4">
                <img
                  src="images/slider4.jpg"
                  alt="Instalación de Toldo 4"
                  className="w-full h-full object-cover rounded-lg"
                />
                <div className="absolute inset-0 flex items-center justify-center text-white text-4xl opacity-30 font-bold">
                  <span>Toldos Muños Martin</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Sección de Nosotros */}
      <section id="about" className="bg-light-gray py-20">
        <div className="max-w-7xl mx-auto px-4 text-center">
          <h2 className="text-5xl font-medium">¿Quiénes somos?</h2>
          <small className="text-gray-500">
            Descubre mas sobre Toldos Muñoz Martin S.L
          </small>
          <div className="grid grid-cols-1  text-start lg:grid-cols-2 gap-12 mt-8">
            <div className="flex flex-col justify-start">
              <h3 className="text-3xl font-medium mb-4">- Nuestra Historia</h3>
              <p className="text-base mb-6 text-gray-500">
                <strong>Toldos Muñoz Martín S.L.</strong>, una empresa familiar
                con más de tres décadas de experiencia en el sector de
                instalaciones y montajes de toldos y sistemas de protección
                solar. Desde nuestros inicios, hemos trabajado con el compromiso
                de ofrecer soluciones personalizadas, funcionales y estéticas
                que se adapten a las necesidades de nuestros clientes.
              </p>
              <p className="text-base mb-6 text-gray-500">
                En Toldos Muñoz Martín, entendemos que la protección frente al
                sol y las inclemencias del tiempo es esencial para disfrutar de
                tus espacios al máximo. Por eso, nuestro objetivo es brindarte
                productos de calidad que combinen diseño, durabilidad y
                eficiencia, logrando un equilibrio perfecto entre confort y
                estilo.
              </p>
              <ul className="text-base mb-6 text-gray-500 space-y-2">
                <li>
                  <strong>Experiencia comprobada:</strong> Más de 30 años en el
                  mercado avalan nuestra trayectoria y conocimiento en el
                  sector.
                </li>
                <li>
                  <strong>Asesoramiento personalizado:</strong> Trabajamos mano
                  a mano contigo para encontrar la solución que mejor se ajuste
                  a tus necesidades y presupuesto
                </li>
                <li>
                  <strong>Productos de calidad:</strong> Utilizamos materiales
                  resistentes y de primera calidad, garantizando resultados
                  duraderos y estéticamente impecables.
                </li>
              </ul>
              <Link
                to={""}
                className="text-blue-400 flex flex-row gap-4 items-center"
              >
                ...leer mas
                <ArrowRight />
              </Link>
            </div>
            <div className="flex justify-center relative">
              <img
                src="images/about-us.jpg"
                alt="Nuestro equipo"
                className="rounded-3xl"
              />
              <div className="absolute inset-0 flex items-center justify-center text-white text-4xl opacity-30 font-bold">
                <span>Toldos Muños Martin</span>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Sección de Servicios */}
      <section id="services" className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 text-center">
          <h2 className="text-5xl font-medium">Nuestros Servicios</h2>
          <small className="text-gray-500">
            Descubre todos los servicios que ofrecemos.
          </small>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-10">
            {/* Servicio 1 */}
            <div className="p-6 border rounded-3xl">
              <div className="rounded-xl mb-4 aspect-[10/8] relative">
                <img
                  src="images/pergola.jpg"
                  alt="Instalación de Toldos"
                  className="rounded-xl mb-4 aspect-[10/8]"
                />
                <div className="absolute inset-0 flex items-center justify-center text-white text-4xl opacity-30 font-bold">
                  <span>Toldos Muños Martin</span>
                </div>
              </div>
              <h3 className="text-2xl text-start font-medium mb-4">Pergolas</h3>
              <p className="text-base text-start text-gray-500">
                Transforma tu espacio exterior con nuestras pérgolas de alta
                calidad. Perfectas para terrazas, jardines o comercios,
                ofrecemos soluciones personalizadas que combinan estilo y
                funcionalidad. ¡Disfruta de un ambiente único y protegido
                durante todo el año!
              </p>
            </div>
            {/* Servicio 2 */}
            <div className="p-6 border rounded-3xl">
              <div className="rounded-xl mb-4 aspect-[10/8] relative">
                <img
                  src="images/sandwish.jpg"
                  alt="Instalación de Toldos"
                  className="rounded-xl mb-4 aspect-[10/8]"
                />
                <div className="absolute inset-0 flex items-center justify-center text-white text-4xl opacity-30 font-bold">
                  <span>Toldos Muños Martin</span>
                </div>
              </div>

              <h3 className="text-2xl text-start font-medium mb-4">
                Panel Sandwich
              </h3>
              <p className="text-base text-start text-gray-500">
                Instalamos paneles sandwich de alta calidad para un aislamiento
                térmico y acústico superior. Ideal para mejorar la eficiencia y
                confort de tu espacio. ¡Protege y optimiza tu entorno de manera
                rápida y efectiva!
              </p>
            </div>
            {/* Servicio 2 */}
            <div className="p-6 border rounded-3xl">
              <div className="rounded-xl mb-4 aspect-[10/8] relative">
                <img
                  src="images/slider1.jpg"
                  alt="Instalación de Toldos"
                  className="rounded-xl mb-4 aspect-[10/8]"
                />
                <div className="absolute inset-0 flex items-center justify-center text-white text-4xl opacity-30 font-bold">
                  <span>Toldos Muños Martin</span>
                </div>
              </div>
              <h3 className="text-2xl text-start font-medium mb-4">
                Lonas de piscina
              </h3>
              <p className="text-base text-start text-gray-500">
                Protege tu piscina con nuestras lonas de alta calidad, diseñadas
                para mantener el agua limpia y segura. Resistentes y duraderas,
                nuestras lonas ofrecen una excelente protección contra la
                suciedad y las inclemencias del tiempo. ¡Asegura tu piscina con
                estilo y funcionalidad!
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Sección de Proyectos */}
      {/* <section id="projects" className="bg-light-gray py-20">
        <div className="max-w-7xl mx-auto px-4 text-center">
          <h2 className="text-3xl font-semibold mb-8">
            Nuestros Proyectos Recientes
          </h2>
          <p className="text-lg mb-8">
            Mira algunos de nuestros proyectos más destacados donde instalamos
            toldos de alta calidad y diseño a medida.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
            </div>
            <div>
              <img
                src="https://via.placeholder.com/600x400?text=Proyecto+2"
                alt="Proyecto 2"
                className="w-full h-auto rounded-lg mb-4"
              />
              <p className="text-xl">
                Instalación de toldos para restaurante en la zona comercial.
              </p>
            </div>
            <div>
              <img
                src="https://via.placeholder.com/600x400?text=Proyecto+3"
                alt="Proyecto 3"
                className="w-full h-auto rounded-lg mb-4"
              />
              <p className="text-xl">
                Toldo especializado para un comercio local, protección contra el
                sol.
              </p>
            </div>
          </div>
        </div>
      </section> */}

    </div>
  );
}
