import React, { ReactNode } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

interface DefaultLayoutProps {
  children: ReactNode;
}

const Layout: React.FC<DefaultLayoutProps> = ({ children }) => {
  return (
    <>
      <Navbar />
      <main className="overflow-y-auto pt-10 lg:pt-16">{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
