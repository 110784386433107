import { ArrowRight } from "iconsax-react";
import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div>
      <nav className="flex flex-wrap w-full items-center justify-between p-4 z-50 top-0 fixed border-b md:px-8 bg-white">
        {/* Logo / Title */}
        <div className="w-auto  lg:w-1/5 lg:text-center flex flex-row items-center gap-4">
          <img src="images/logo.svg" alt="" className="w-14 h-14" />
          <Link
            className="text-xl font-medium text-gray-900 font-heading flex flex-col justify-start items-start"
            to="#"
          >
            Toldos Muñoz Martin
            <small className="text-xs text-gray-500">
              Instalaciones & Montajes
            </small>
          </Link>
        </div>

        {/* Mobile Menu Button */}
        <div className="block lg:hidden">
          <button
            onClick={toggleMenu}
            className="flex items-center px-3 py-2  text-indigo-500 border border-indigo-500 rounded"
          >
            <svg
              className="w-3 h-3 fill-current"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
            </svg>
          </button>
        </div>

        <div className="flex flex-col lg:flex-row">
          <div
            className={`${
              isMenuOpen ? "block" : "hidden"
            } w-full navbar-menu  lg:block  lg:text-right`}
          >
            <Link
              className="block mt-4 mr-10 text-blue-900 lg:inline-block lg:mt-0 hover:text-indigo-600"
              to="/"
            >
              Inicio
            </Link>
            <Link
              className="block mt-4 mr-10 text-blue-900 lg:inline-block lg:mt-0 hover:text-indigo-600"
              to="/about-us"
            >
              Sobre Nosotros
            </Link>
            <Link
              className="block mt-4 mr-10 text-blue-900 lg:inline-block lg:mt-0 hover:text-indigo-600"
              to="/our-work"
            >
              Nuestro Trabajo
            </Link>
            <Link
              className="block mt-4 mr-10 text-blue-900 lg:inline-block lg:mt-0 hover:text-indigo-600"
              to="/our-work"
            >
              Servicios
            </Link>

            <Link
              className="block mt-4 mr-10 text-blue-900 lg:inline-block lg:mt-0 hover:text-indigo-600"
              to="/contact"
            >
              Contacto
            </Link>
            <Link
              className="block mt-4 mr-10 p-4 bg-blue-800 lg:inline-block lg:mt-0 hover:bg-indigo-600 text-white rounded-lg"
              to="/get_quote"
            >
              <div className="flex flex-row justify-between items-center gap-8">
                Obtén tu presupuesto
                <ArrowRight size={20} color="white" />
              </div>
            </Link>
          </div>
        </div>
      </nav>
    </div>
  );
}
